import { Link } from "gatsby"
import * as React from "react"

import {Carousel,Container,Row,Col,Card,CardDeck} from  'react-bootstrap'
import Layout from "../components/layout"
import SEO from "../components/seo"
import photo1 from '../images/ph.jpg'
import photo2 from '../images/photo2.jpg'
import photo3 from '../images/photo3.jpg'
import photo4 from '../images/photo4.jpg'
import photo5 from '../images/photo5.webp'
import banner1 from '../images/banner1.jpg'
import banner2 from '../images/banner2.jpg'
import banner3 from '../images/banner3.jpg'

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Carousel>
  <Carousel.Item interval={3000}>
     <h1 className="h1-slider">swissartmaler</h1>
    <img className="d-block w-100" src={banner1} alt="First slide" />
  </Carousel.Item>
  <Carousel.Item interval={3000}>
    <h1 className="h1-slider">swissartmaler</h1>
    <img className="d-block w-100" src={banner2} alt="Second slide" />
  </Carousel.Item>
  <Carousel.Item interval={3000}>
    <h1 className="h1-slider">swissartmaler</h1>
    <img className="d-block w-100" src={banner3} alt="Third slide"/>
  </Carousel.Item>
</Carousel>
<Container  fluid="md" className="about-startpage">
 <Row>
   <Col sm={8} md={8}>
   <h3 className="header-about">Willkomen bei der Swissartmaler</h3>
   <h4 className="header-second">Wir sind eine mittelständische Zürich-Glarner Unternehmer mit proffesionelle Mitarbeitenden.</h4>
   <p className="paragraph">Unsere Qualität basiert auf unseren drei Fachabteilungen Malerei, Gipserarbeiten und Isolieren. Desweiteren führen wir seit mehreren Jahren Sanierungen im kleinen sowie im grossen Rahmen durch und sind somit der ideale Bauleiter für Renovationsarbeiten in Wohnungen sowie in Liegenschaften.
      Erfahrung und Vertrauen – Mit bewährten Partnerfirmen bieten wir massgeschneiderte Lösungen an.Als Kunde werden Sie individuell, persönlich wie auch kompetent betereut und beraten.</p>
   </Col>
   <Col className="img-start" sm={4} md={2}>
   <img className="img-startpage" src={photo5} />
   </Col>
 </Row>
</Container>
<Container>
<CardDeck>
  <Card className="card">
    <Link className="link-services" to="/uberuns">
    <Card.Img variant="top" src={photo1} />
    <Card.Body>
      <Card.Title>WILKOMEN</Card.Title>
      <Card.Text className="paragraph">
      Wir sind eine mittelständische Zürich-Glarner Unternehmer mit proffesionelle Mitarbeitenden.
      Unsere Qualität basiert auf unseren drei Fachabteilungen Malerei, Gipserarbeiten und Isolieren.
      </Card.Text>
    </Card.Body>
    </Link>
  </Card>
  <Card>
  <Link className="link-services" to="/angebote">
    <Card.Img variant="top" src={photo2} />
    <Card.Body>
      <Card.Title>ANGEBOTE</Card.Title>
      <Card.Text className="paragraph">
      Wir arbeiten: Umbau  Renovation,Innere Malerarbeiten, Fassadenarbeiten, schimmelsanierungen, tapezierarbeiten, 
      Neubau, innere malerarbeiten, Spritzarbeiten.
        content.{' '}
      </Card.Text>
    </Card.Body>
    </Link>
  </Card>
  <Card>
  <Link className="link-services" to="/angebote">
    <Card.Img variant="top" src={photo3} />
    <Card.Body>
      <Card.Title>REFERENZEN</Card.Title>
      <Card.Text className="paragraph">
      Wir begleiten unsere Kunden von A biz Z. Durch stetige Weiterbildungen an Seminaren und Lehrgängen ist unser Wissen über neue Produkte, 
      Trends und Vorchriften ständig auf dem neuesten Stand.
      </Card.Text>
    </Card.Body>
    </Link>
  </Card>
  <Card>
  <Link className="link-services" to="/kontakt">
    <Card.Img variant="top" src={photo4} />
    <Card.Body>
      <Card.Title>KONTAKT</Card.Title>
      <Card.Text className="paragraph">
      Unsere Adresse ist: Im Zetrum 10.<br/>
      8634 Hombrechtikon.<br/>
      Tel:078 60 40 400; Fax:055 280 40 60 Email:info@swissartmaler.ch
      Web:swissartmaler.ch
      </Card.Text>
    </Card.Body>
    </Link>
  </Card>
</CardDeck>
</Container>
  </Layout>
)

export default IndexPage
